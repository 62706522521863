import { initializeApp } from "firebase/app"
import { getMessaging, getToken } from "firebase/messaging"
import Cookies from 'universal-cookie'
import toast from "react-hot-toast"
// import { messaging } from "../../config/firebase"
// import { createTokenPushRequest } from '../../config/rates'
import { firebaseConfig } from '../config/constants'

export async function requestFCM(translation) {
    console.log('translation', translation)
    const cookies = new Cookies();
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);

    console.log('Requesting permission...');
    try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
            console.log('Notification permission granted.');
            // Get token
            const currentToken = await getToken(messaging, {
                vapidKey: 'BG9PwHHC9oBSOawqBprraEIWaefa4qNbeex6q9xZN_ZNkAoAjVjS7e80755K-u7I0vSjFwcYOska5ZTifrrQMV8',
            });

            if (currentToken) {
                // console.log('FCM token:', currentToken);
                cookies.set('push-notification-token', currentToken, { path: '/' });
                return currentToken; // Return the token
            } else {
                console.log('No registration token available. Request permission to generate one.');
                toast.error(translation("userSettings.pushNotifications.messages.noTokenAvailable"));
                return null;
            }
        } else if (permission === 'denied') {
            console.log('You denied the push-notifications.');
            toast.error(translation("userSettings.pushNotifications.messages.deniedPushNotifications"));
            return null;
        }
    } catch (error) {
        console.error('Notification error:', error);
        toast.error(translation("userSettings.pushNotifications.messages.requestNotificationError"));
        return null;
    }
}