import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './assets/locales/en/common.json';
import ruTranslations from './assets/locales/ru/common.json';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslations,
      },
      ru: {
        translation: ruTranslations,
      },
    },
    fallbackLng: 'en',
    detection: {
      order: ['localStorage', 'cookie', 'navigator'],
      lookupFromPathIndex: 0,
      caches: ['localStorage', 'cookie'],
    },
    load: 'languageOnly',
    interpolation: {
      escapeValue: false, // Disable HTML escaping
    },
  });

export default i18n;
