import { useTranslation } from "react-i18next";
import CollapsedList from "../../components/CollapsedList/CollapsedList";
import styles from "./FAQ.module.sass";

const FAQ = () => {
  const { t } = useTranslation();
  const list = t("faq.list", { returnObjects: true });

  const replacementList = [
    ,
    ,
    ,
    ,
    ,
    {
      text: t("faq.list.5.text", { amount: "0.3 BNB" }),
    },
    {
      text: t("faq.list.6.text", {
        amountPercentages:
          "<ul><li>0.3-2.99BNB — 0.9% = 328.5% APR</li><li>3-9.99BNB — 1% = 365% APR</li><li>10-99 — 1.05% = 383% APR</li><li>100-999BNB — 1.09% 397% APR</li><li>1000BNB+ — 1.3% = 475% APR</li></ul>",
        depositAmount: "1 BNB",
        dailyIncome: "0.009 BNB",
      }),
    },
    {
      text: t("faq.list.7.text", { minimumInvestment: "0.1 BNB" }),
    },
  ];

  for (let i = 0; i < list.length; i++) {
    if (replacementList[i] && replacementList[i]["title"])
      list[i]["title"] = replacementList[i]["title"];
    if (replacementList[i] && replacementList[i]["text"])
      list[i]["text"] = replacementList[i]["text"];
  }
  
  return (
    <section className={styles.faq} id="faq">
      <h2 className={styles.title}>
        {t("faq.title")}
      </h2>
      <div className={styles.list}>
        <CollapsedList data={list} />
      </div>
    </section>
  );
};

export default FAQ;
