import { useEffect, useMemo, useState, useRef } from "react";
import { useGlobalStateContext } from "../../context/globalState";
import { useAccount, useDisconnect } from "wagmi";
import Cookies from "universal-cookie";
import { bnbRateRequest } from "../../config/requests/requests";
import { useWalletConnectorContext } from "../../context/contract";
import { truncateInside } from "../../tools/common";
import classNames from "classnames";
import Button from "../../components/Button";
import OnboardingOfferModal from "../../components/Popups/OnboardingOfferModal";
import { NotificationsList } from "../../legacy/Notification-bot";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import LangMultiSwitcher from "../../components/LangSwitcher/ui/multiswitcher";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import { TabletDesktop } from "../../components/Responsive";
import SocialsList from "../../components/SocialsList";
import { useMediaQuery } from "react-responsive";
import styles from "./Header.module.sass";
import "swiper/css";
import "swiper/css/effect-fade";
import { formatEther } from "viem";
import { mainDomain } from "../../config/constants";

import { GearIcon, closeIco, OpenedBookIcon } from "../../assets/icons";
import BlackLogo from "../../assets/img/logo-dark.svg";

const Header = ({
  openedUserSettings,
  setOpenedUserSettings,
  onboardingDriver,
}) => {
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const { t, i18n, ready } = useTranslation();
  const cookies = new Cookies();

  const onboardingStatus = cookies.get("onboardingStatus");
  const [bnbRate, setBnbRate] = useState(null);
  const isMobile = useMediaQuery({ query: "(max-width: 1239px)" });
  const { currency } = useGlobalStateContext();

  const [connectWalletText, setConnectWalletText] = useState(
    t("header.btn_connect")
  );
  
  const [isMounted, setIsMounted] = useState(false);
  const [isNotice, setIsNotice] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(true);

  const { open } = useWeb3Modal();
  const news = ready && t("header.list", { returnObjects: true, landingDomain: mainDomain });
  const menuList = ready && t("header.menu", { returnObjects: true });
  const [menuOpen, setMenuOpen] = useState(false);

  const menuRef = useRef(null);
  const barRef = useRef(null);
  const barContentRef = useRef(null);

  const { investmentAmount } = useWalletConnectorContext();

  useEffect(() => {
    setConnectWalletText(
      address ? truncateInside(address) : t("header.btn_connect")
    );

    // GTAT event - Connect Wallet
    if (typeof window !== "undefined" && address) {
      const isRegularCustomer = localStorage.getItem("isRegularCustomer");
      if (!isRegularCustomer) {
        window.dataLayer.push({
          event: "connect_wallet",
          wallet_address: `=${address}`,
        });
        console.log(
          "GA4 Event",
          `Connect Wallet event from ${address} is sended successfuly!`
        );
        localStorage.setItem("isRegularCustomer", true);
      }
    }
  }, [address, i18n.language]);

  useEffect(() => setIsMounted(!isMounted), []);

  useEffect(() => {
    let host = window.location.host;
    host = host.replace(/^[^.]+\./g, ""); // Here we assume that this site has a subdomain
  }, [i18n.language]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const out = await bnbRateRequest();
        setBnbRate((prev) => {
          const status =
            prev <= out[0] ? (
              <svg
                className={styles.upIcon}
                key="upIcon"
                width="8"
                height="11"
                viewBox="0 0 8 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M4 10.5V1.5M4 1.5L7 4.5M4 1.5L1 4.5" />
              </svg>
            ) : (
              <svg
                className={styles.downIcon}
                key="downIcon"
                width="8"
                height="11"
                viewBox="0 0 8 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M4 10.5V1.5M4 1.5L7 4.5M4 1.5L1 4.5" />
              </svg>
            );
          return [out[0], status];
        });
      } catch (error) {
        console.error("Error fetching BNB rate:", error);
      }
    };

    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 2 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleToggleMenu = () => setMenuOpen(!menuOpen);

  const handleSticky = () => {
    setIsSticky(window.scrollY > 500);
  };

  useEffect(() => {
    if (isMobile) {
      handleSticky();
      window.addEventListener("scroll", handleSticky, { passive: true });
    }
    return () => {
      if (isMobile) {
        window.removeEventListener("scroll", handleSticky);
      }
    };
  }, [isMobile]);

  const connectHandler = () => {
    isConnected ? disconnect() : open();
  };

  const notificationsHandler = () => {
    if (isNotice) {
      setIsNotice(false);
    } else {
      setIsNotice(true);
    }
  };

  const startOnboardingHandler = () => {
    // disconnect wallet for start first stage of tour (disconnected status is required for start)
    disconnect();
    setMenuOpen(false); // then: close menu
    // remove onboarding info from cookies
    cookies.remove("onboardingStatus", {
      path: "/",
      domain: document.location.hostname,
    });
    setIsOnboardingModalOpen(true);
  };

  return (
    <>
      <header
        className={classNames(
          styles.header,
          isSticky && styles.headerSticky,
          menuOpen && styles.headerMenuOpened
        )}
      >
        <div
          className={classNames(styles.bar, menuOpen && styles.barOpened)}
          ref={barRef}
        >
          <button
            className={styles.menuButton}
            type="button"
            onClick={handleToggleMenu}
          >
            {menuOpen ? (
              <svg
                className={styles.menuButtonIcon}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L12 12M23 23L12 12M12 12L23 1L1 23"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                className={styles.menuButtonIcon}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="2" cy="2" r="2" fill="black" />
                <circle cx="12" cy="2" r="2" fill="black" />
                <circle cx="22" cy="2" r="2" fill="black" />
                <circle cx="2" cy="12" r="2" fill="black" />
                <circle cx="12" cy="12" r="2" fill="black" />
                <circle cx="22" cy="12" r="2" fill="black" />
                <circle cx="2" cy="22" r="2" fill="black" />
                <circle cx="12" cy="22" r="2" fill="black" />
                <circle cx="22" cy="22" r="2" fill="black" />
              </svg>
            )}
          </button>
          <div
            className={classNames(
              styles.barContent,
              menuOpen && styles.barContentOpened
            )}
            ref={barContentRef}
          >
            <img
              className={styles.logoImage}
              src={BlackLogo}
              alt="Antidote"
              width={164}
              height={88}
            />
            {menuList && (
              <nav className={styles.menu}>
                <ul className={styles.menuList} ref={menuRef}>
                  {menuList.map((item, i) => {
                    const currentLocation = window.location.hostname
                    const hostnameWithoutSub = currentLocation
                      ? currentLocation.replace(/(^\w+:|^)\/\//, '').split('.').slice(-2).join('.')
                      : currentLocation;
                    const language = ['en', 'ru'].includes(i18n.language) ? i18n.language : '';

                    return (
                      <li key={i} className={styles.menuItem}>
                        <a
                          className={styles.menuLink}
                          href={`${window.location.protocol}//${hostnameWithoutSub}/${language}${item.url}`}
                        >
                          {item.title}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            )}
            <div className={styles.barFooter}>
              <Button
                text={connectWalletText}
                className={styles.connectButton}
                onClick={connectHandler}
                size="Md"
              />
              <div className={styles.socials}>
                <SocialsList mod="BarContent" />
              </div>
            </div>
          </div>
          <div className={styles.onboarding}>
            <button
              className={styles.onboarding__button}
              onClick={startOnboardingHandler}
            >
              <img src={OpenedBookIcon} alt="open onboarding" />
            </button>
          </div>
        </div>
        <div className={styles.panel}>
          <div className="container">
            <div className={styles.panelInner}>
              <div className={styles.panelText}>
                {news && (
                  <div className={styles.slider}>
                    <Swiper
                      className={classNames(
                        styles.sliderEl,
                        "header-news-slider"
                      )}
                      resizeObserver={true}
                      updateOnWindowResize={true}
                      effect={"fade"}
                      loop={true}
                      slidesPerView={1}
                      autoplay={{
                        delay: 8000,
                        disableOnInteraction: false,
                      }}
                      modules={[Autoplay, EffectFade]}
                    >
                      {news.map((item, index) => (
                        <SwiperSlide
                          key={`header-news-item-${index}`}
                          className={styles.sliderItem}
                        >
                          <a
                            className={styles.panelLink}
                            href={item.url}
                            target="_blank"
                          >
                            <span className={styles.panelLinkLabel}>
                              {item.title}
                            </span>
                            <span className={styles.panelLinkIcon}>
                              <svg
                                className={styles.panelLinkIconI}
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1 13L13 1M13 1V7.25M13 1H6.5"
                                  stroke="#252526"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                          </a>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                )}
              </div>
              {bnbRate && (
                <TabletDesktop>
                  <div className={styles.exchangeRate}>1 BNB = ${bnbRate}</div>
                </TabletDesktop>
              )}
            </div>
          </div>
        </div>
        <div className={styles.main}>
          <div className="container">
            <div className={styles.inner}>
              <div className={styles.aside}>
                {!!investmentAmount && (
                  <div className={styles.info}>
                    <div
                      className={classNames(styles.deposit, {
                        [styles.openedSettings]: openedUserSettings,
                      })}
                      id="onboaring__deposit"
                      style={{color: 'white'}}
                    >
                      <span>{t("header.yourDeposit")}</span>
                      <span>
                        {currency === "bnb"
                          ? `${parseFloat(
                              (+formatEther(investmentAmount)).toFixed(5)
                            )}`
                          : bnbRate[0] &&
                            `${parseFloat(
                              (
                                +formatEther(investmentAmount) * bnbRate[0]
                              ).toFixed(5)
                            )}`}
                        <span className={styles.deposit__currency}>
                          {currency}
                        </span>
                      </span>
                    </div>
                  </div>
                )}
                <div
                  className={classNames(styles.settingsWrapper, {
                    [styles.opened]: openedUserSettings,
                  })}
                >
                  <span className={styles.label}>
                    {t("header.buttons.settings.label")}
                  </span>
                  {address && <button
                    className={classNames(styles.circleToggle, styles.settings)}
                    id="onboaring__settingsButton"
                    type="button"
                    onClick={() => setOpenedUserSettings(!openedUserSettings)}
                  >
                    <img
                      src={GearIcon}
                      className={styles.gearIcon}
                      alt="User settings"
                    />
                    <img
                      src={closeIco}
                      className={styles.closeIcon}
                      alt="User settings"
                    />
                  </button>}
                </div>
                <div className={styles.notifications}>
                  <button
                    className={styles.circleToggle}
                    type="button"
                    onClick={notificationsHandler}
                  >
                    <div className={styles.notificationsWrap}>
                      <svg
                        className={styles.notificationsIcon}
                        width="18"
                        height="23"
                        viewBox="0 0 18 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.2446 19.4964H0.747596C0.619771 19.496 0.494166 19.463 0.382711 19.4004C0.271256 19.3378 0.17765 19.2477 0.110783 19.1388C0.0439155 19.0299 0.00600694 18.9056 0.000657128 18.7779C-0.00469268 18.6502 0.022694 18.5233 0.0802161 18.4091C1.00545 16.5443 1.49031 14.4919 1.49746 12.4102V9.74816C1.49746 7.7594 2.28749 5.85208 3.69376 4.44582C5.10003 3.03955 7.00734 2.24951 8.99611 2.24951C10.9849 2.24951 12.8922 3.03955 14.2985 4.44582C15.7047 5.85208 16.4948 7.7594 16.4948 9.74816V12.3952C16.5019 14.4769 16.9868 16.5293 17.912 18.3941C17.9731 18.5088 18.0033 18.6373 17.9997 18.7672C17.9961 18.8971 17.9589 19.0238 17.8916 19.1349C17.8243 19.2461 17.7293 19.3378 17.6159 19.4012C17.5025 19.4645 17.3745 19.4974 17.2446 19.4964ZM1.90989 17.9967H16.0823C15.3673 16.2156 14.9982 14.3145 14.995 12.3952V9.74816C14.995 8.15715 14.363 6.6313 13.238 5.50628C12.113 4.38127 10.5871 3.74924 8.99611 3.74924C7.4051 3.74924 5.87925 4.38127 4.75423 5.50628C3.62922 6.6313 2.99719 8.15715 2.99719 9.74816V12.3952C2.99399 14.3145 2.62497 16.2156 1.90989 17.9967Z"
                          fill="#B2B2B2"
                        />
                        <path
                          d="M8.99737 22.4963C8.00299 22.4963 7.04933 22.1012 6.3462 21.3981C5.64306 20.695 5.24805 19.7413 5.24805 18.7469C5.24805 18.5481 5.32705 18.3573 5.46768 18.2167C5.6083 18.0761 5.79904 17.9971 5.99791 17.9971C6.19679 17.9971 6.38752 18.0761 6.52815 18.2167C6.66877 18.3573 6.74778 18.5481 6.74778 18.7469C6.7483 19.3434 6.98566 19.9152 7.40766 20.3366C7.83679 20.7438 8.4058 20.9708 8.99737 20.9708C9.58894 20.9708 10.158 20.7438 10.5871 20.3366C10.657 20.2667 10.74 20.2113 10.8314 20.1734C10.9227 20.1356 11.0206 20.1161 11.1195 20.1161C11.2184 20.1161 11.3163 20.1356 11.4076 20.1734C11.499 20.2113 11.582 20.2667 11.6519 20.3366C11.7218 20.4066 11.7773 20.4896 11.8151 20.5809C11.8529 20.6723 11.8724 20.7702 11.8724 20.8691C11.8724 20.9679 11.8529 21.0658 11.8151 21.1572C11.7773 21.2485 11.7218 21.3315 11.6519 21.4015C11.3031 21.7494 10.8891 22.0252 10.4336 22.213C9.97811 22.4009 9.49007 22.4971 8.99737 22.4963Z"
                          fill="#B2B2B2"
                        />
                        <path
                          d="M8.99401 3.74932C8.79513 3.74932 8.6044 3.67032 8.46377 3.52969C8.32314 3.38907 8.24414 3.19834 8.24414 2.99946V0.749865C8.24414 0.550988 8.32314 0.360257 8.46377 0.21963C8.6044 0.0790034 8.79513 0 8.99401 0C9.19288 0 9.38361 0.0790034 9.52424 0.21963C9.66487 0.360257 9.74387 0.550988 9.74387 0.749865V2.99946C9.74387 3.19834 9.66487 3.38907 9.52424 3.52969C9.38361 3.67032 9.19288 3.74932 8.99401 3.74932Z"
                          fill="#B2B2B2"
                        />
                      </svg>
                      <div className={styles.notificationsPoint} />
                    </div>
                  </button>
                  <div
                    className={classNames(
                      styles.notificationsContent,
                      isNotice && styles.notificationsContentOpened
                    )}
                  >
                    <NotificationsList />
                  </div>
                </div>
                <TabletDesktop>
                  {
                    address ? (
                      <Button
                        text={t("investing.disconnectWalletButtonLabel")}
                        className={styles.logoutButton}
                        onClick={connectHandler}
                        mod="BorderedGrey"
                        size="Md"
                      />
                    ) : (
                      <Button
                        text={t("investing.connectWalletButtonLabel")}
                        className={styles.logoutButton}
                        onClick={connectHandler}
                        mod="yellowBordered"
                        size="Md"
                      />
                    )
                  }
                </TabletDesktop>
                <div className={styles.langSwitcher}>
                  <LangMultiSwitcher />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {isMounted &&
        !isConnected &&
        !(onboardingStatus !== undefined && onboardingStatus !== null) &&
        isOnboardingModalOpen && (
          <OnboardingOfferModal
            setIsPopupOpen={setIsOnboardingModalOpen}
            onboardingDriver={onboardingDriver}
          />
        )}
    </>
  );
};

export default Header;
