import axios from "axios";
import { readContract } from "@wagmi/core";
import abi from "../abi";
import {
  tokenAddress,
  arbitrageBot,
  reportLink,
  bscApiKey,
  readerSvcDomain,
  devRequestsDomain,
  notificationDomain,
} from "../constants";
import toast from "react-hot-toast";

/* Currency rates */
export const bnbRateRequest = async (currentNetwork = "BNB") => {
  // bnb rate
  // get BNB/USD rate from Binance
  const bnbUsdRate = await axios({
    method: "get",
    url: `https://api.binance.com/api/v3/ticker/price?symbol=${currentNetwork}USDT`,
    withCredentials: false,
  });

  return [Number(bnbUsdRate.data.price).toFixed(2), bnbUsdRate.data.price];
};
/* end */

/* Get transactions history (from BSC) */
export const bscscanTransactionsRequest = async (hash, action = "txlist") => {
  if (!hash) return;
  const [module, startblock, endblock, page, sort, limit, offset] = [
    "account",
    0,
    99999999,
    0,
    "desc",
    1,
    1,
  ];
  return await axios({
    method: "get",
    url:
      action === "txlistinternal"
        ? `https://api.bscscan.com/api?module=${module}&action=${action}&txhash=${hash}&apikey=${bscApiKey}`
        : `https://api.bscscan.com/api?module=${module}&action=${action}&address=${hash}&startblock=${startblock}&endblock=${endblock}&page=${page}&offset=${offset}&sort=${sort}&limit=${limit}&apikey=${bscApiKey}`,
  });
};
/* end */

/* Read smart contract (web3) */
export const getContract = async (address, functionName) => {
  if (address) {
    try {
      return await readContract({
        address: tokenAddress,
        abi,
        functionName,
        args: [address],
      });
    } catch (e) {
      console.log(e);
      toast.error("Request: Invalid address");
    }
  }
};
/* end */

// get server timestamp 
export const getServerTimestamp = () => {
  try {
    return axios.get(
      `${devRequestsDomain}/timestamp`
    ).catch(err => console.log('getServerTimestamp request failed', err));
  } catch (err) {
    console.log("Error getUndepositedReferrals", err);
  }
};
/* end */

/* Referral System */
// change inviter address
// get inviter
export const getUndepositedReferrals = (walletAddress) => {
  if (walletAddress) {
    try {
      return axios.get(
        `${devRequestsDomain}/user-undeposited-referrals/${walletAddress}`
      ).catch(err => console.log('user-undeposited-referrals request failed', err));
    } catch (err) {
      console.log("Error getUndepositedReferrals", err);
    }
  }
};
/* end */

export const userExistsRequest = async (address) => {
  if(!address) return console.error('userExistsRequest: adddress paramater is required!')
  return await axios({
    method: "get",
    url: `${devRequestsDomain}/user-exists/${address}`,
  })
};

export const getUserMetaRequest = async (address) => {
  if(!address) return console.error('getUserMetaRequest: adddress paramater is required!')
  return await axios({
    method: "get",
    url: `${devRequestsDomain}/metadata/${address}`,
  }).catch(err => {
    console.error('getUserMetaRequest: Can\'t get user profile data', err);
  });
};

export const setNewMetadata = (Address, Mail, Name, Inviter, Contact, ContactType, Timestamp, Signature) => {
  const bodyRequest = {
    Address,
    Mail,
    Name,
    Inviter,
    Timestamp: Number(Timestamp),
    Signature
  }

  if( ContactType.toString() === "0" ) {
    bodyRequest['Telegram'] = Contact;
  } else {
    bodyRequest['Whatsapp'] = Contact;
  }

  const cleanedBodyRequest = Object.fromEntries(
    Object.entries(bodyRequest).filter(([key, value]) => value !== undefined && value !== null && value !== "")
  );

  if (Address && Timestamp && Signature) {
    try {
      return axios.patch(`${devRequestsDomain}/metadata`, cleanedBodyRequest);
    } catch (err) {
      console.log("Error setNewInviter", err);
    }
  }
};

export const userRegistrationRequest = async (Address, Inviter, Timestamp, Signature) => {
  if (Address && Inviter && Timestamp && Signature) {
    try {
      return axios.post(`${devRequestsDomain}/registration`, {
        Address,
        Inviter,
        NotUsaCitizen: true,
        Timestamp: Number(Timestamp),
        Signature,
      });
    } catch (err) {
      console.log("Error userRegistrationRequest", err);
      return err;
    }
  } else {
    console.error('Registration form: some of reuired data is missed.')
  }
};

export const getReferralDeposits = async (wallets) => {
  if (wallets) {
    try {
      return axios.post(`${readerSvcDomain}/users-deposits`, {
        Address: wallets,
      });
    } catch (err) {
      console.log("Error referralDeposits", err);
      return err;
    }
  }
};

export const getUsersContacts = async (wallets) => {
  if (wallets) {
    try {
      return axios.post(`${devRequestsDomain}/users-contacts`, {
        Address: wallets,
      }).catch(err => console.log('users-contacts request failed', err));;
    } catch (err) {
      console.log("Error users-contacts", err);
      return err;
    }
  }
};

/* Indexes */
export const arbitrageBotIndexes = async () => {
  return await Promise.all([
    axios.get(`${arbitrageBot}/total-amount-of-deposits`),
    axios.get(`${arbitrageBot}/number-of-withdrawals`),
    axios.get(`${arbitrageBot}/total-investors`),
    axios.get(`${arbitrageBot}/total-amount-of-liquidity`),
    axios.get(`${arbitrageBot}/total-profit`),
    axios.get(`${arbitrageBot}/today-profit`),
  ]);
};
/* end */

/* Notifications Requests */
export const notificationsRequest = async () => {
  // get last records of notifications
  const notificationsLastRecords = await axios({
    method: "get",
    url: `${arbitrageBot}/last-records`,
  }).catch(function (error) {
    console.log(error);
  });

  return notificationsLastRecords;
};
/* end Notifications Requests */

/* Registration counter - users-limit */
export const numberTodayDepositsRequest = async () => {
  return await axios({
    method: "get",
    url: `${readerSvcDomain}/number-of-today-deposits`,
  });
};
/* end */

/* Get information: is firebase cloud messaging token exists */
export const isFcmTokenExistsRequest = async (Address, FcmToken) => {
  if (Address && FcmToken) {
    return axios.post(`${notificationDomain}/is-token-exists`, {
      Address,
      FcmToken,
    })
  } else {
    console.warn('Some of isFcmTokenExistsRequest arguments is missed!')
  }
};
/* end */

/* Get information: is firebase cloud messaging token exists */
export const createFcmTokenRequest = async (Address, FcmToken, Timestamp, Signature) => {
  if (Address && FcmToken && Timestamp && Signature) {
    return axios.post(`${notificationDomain}/create-token`, {
      Address,
      FcmToken,
      Timestamp,
      Signature,
    })
  } else {
    console.warn('Some of createFcmTokenRequest arguments is missed!')
  }
};
/* end */

/* Send error report */
export const bugReport = (errText) => {
  try {
    return axios.post(reportLink, `text=${errText}`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  } catch (err) {
    console.log("bugReport error", err);
  }
};
/* end Send error report */
