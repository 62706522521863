import { useEffect, useState, useRef } from "react";
import { GlobalStateContext } from "./context/globalState";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import { Helmet } from 'react-helmet';
import { Toaster } from "react-hot-toast";
import ThemeSettings from "./components/ThemeSettings";
import {
  Header,
  Intro,
  ContuctUs,
  Footer,
  ReferralsBox,
  // Staking,
  // Referrals,
  Investing,
  InfoSections,
  Profit,
  ReferralBonus,
  ReferralsList,
} from "./sections";
import OnboardingOfferModal from "./components/Popups/OnboardingOfferModal";
import { IS_DEV_SERVER } from "./config/constants";

import { driver } from "driver.js";
import "driver.js/dist/driver.css";

import favicon128x128 from "./assets/img/favicon-128x128.svg"

function App() {
  const cookies = new Cookies();
  const { t, i18n } = useTranslation();

  const [openedUserSettings, setOpenedUserSettings] = useState(false);
  const [onboardingStage, setOnboardingStage] = useState(0);
  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(false);

  const driverTimeoutRef = useRef(null);

  // const onboardingStatus = cookies.get("onboardingStatus");
  const onboardingStatusExpiration = new Date();
  onboardingStatusExpiration.setDate(
    onboardingStatusExpiration.getDate() + 400
  ); // set max expiration date for seve onboarding info to cookies

  const onboardingSteps = t("onboardingSteps", { returnObjects: true });

  const clearExistingTimeout = () => {
    if (driverTimeoutRef.current) {
      clearTimeout(driverTimeoutRef.current);
    }
  };
  
  useEffect(() => {
    const path = window.location.pathname;
    const lang = path.substring(1, 3);
    
    if (lang === 'en' || lang === 'ru') {
      i18n.changeLanguage(lang);
      window.history.replaceState(null, null, '/');
    }

    // If there is no refCode in locale storage but it is in cookies
    // add refCode from cookies to locale storage
    const userPartnerAddressls = localStorage.getItem("partner-address");
    const userPartnerAddresscs = cookies.get("partner-address");
    if( !userPartnerAddressls && userPartnerAddresscs ) localStorage.setItem('partner-address', userPartnerAddresscs);
  }, []);

  const onboardingDriver = driver({
    showProgress: true,
    steps: onboardingSteps[onboardingStage],
    nextBtnText: t("onboardingPopover.nextBtnText"),
    prevBtnText: t("onboardingPopover.prevBtnText"),
    doneBtnText: t("onboardingPopover.doneBtnText"),
    onNextClick: () => {
      onboardingDriver.moveNext();
      driverTimeoutRef.current = setTimeout(() => {
        onboardingDriver.refresh();
      }, 500);
    },
    onPrevClick: () => {
      onboardingDriver.movePrevious();
      driverTimeoutRef.current = setTimeout(() => {
        onboardingDriver.refresh();
      }, 500);
    },
    onDestroyed: () => {
      clearExistingTimeout();
      const onboardingStatus = cookies.get("onboardingStatus");
      let onboardingStatusInfo = null;

      if (!(onboardingStatus !== undefined && onboardingStatus !== null)) {
        onboardingStatusInfo = onboardingSteps.reduce((acc, _, index) => {
          acc[index] = index === onboardingStage;
          return acc;
        }, {});
      } else {
        onboardingStatusInfo = Object.keys(onboardingStatus).reduce(
          (acc, key) => {
            const index = parseInt(key); // Convert key to integer index
            acc[index] =
              index === onboardingStage ? true : onboardingStatus[key];
            return acc;
          },
          {}
        );
      }

      cookies.set("onboardingStatus", JSON.stringify(onboardingStatusInfo), {
        path: "/",
        expires: onboardingStatusExpiration,
        domain: document.location.hostname,
      });

      if (onboardingStage === 3) {
        setIsOnboardingModalOpen(true);
        setOnboardingStage(4);
      }
    },
  });

  // Redirect to new domain
  useEffect(() => {
    if(!IS_DEV_SERVER) return
    
    const currentHostname = window.location.hostname;
    const targetHostname = 'app.iamantidote.com';

    if (currentHostname !== 'localhost' && currentHostname !== targetHostname) {
      const newDomain = `https://${targetHostname}`;
      const currentPath = window.location.pathname + window.location.search + window.location.hash;
      
      window.location.href = newDomain + currentPath;
    }
  }, []);
  
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>ANTIDOTE</title>
        <meta name="description" content="ANTIDOTE cabinet" />
        <link rel="icon" href={favicon128x128} />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link
          rel="preload"
          as="style"
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Bebas+Neue&display=swap"
        />
        <noscript>
          {`<iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-PQ2B2N2K"
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>`}
        </noscript>
      </Helmet>
      <Helmet>
        <script strategy="beforeInteractive" src="https://www.googletagmanager.com/gtag/js?id=G-BZ71JGK8TF"></script>
        <script id="talk-me-script">
          {`
            /* Talk-Me Integrate Script */
            (function () {
              (function c(d, w, m, i) {
                window.supportAPIMethod = m;
                var s = d.createElement("script");
                s.id = "supportScript";
                var id = "fe46cb7822b016be1abfe3253d14515f";
                s.src =
                  (!i
                    ? "https://lcab.talk-me.ru/support/support.js"
                    : "https://static.site-chat.me/support/support.int.js") +
                  "?h=" +
                  id;
                s.onerror = i
                  ? undefined
                  : function () {
                      c(d, w, m, true);
                    };
                w[m] = w[m]
                  ? w[m]
                  : function () {
                      (w[m].q = w[m].q ? w[m].q : []).push(arguments);
                    };
                (d.head ? d.head : d.body).appendChild(s);
              })(document, window, "TalkMe");
            })();
          `}
        </script>
        <script id="gtag-script">
          {`
            // analytics
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-BZ71JGK8TF');

            //gtag
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-PQ2B2N2K');
          `}
        </script>
        <script id="fb-pixel-script">
          {`
            !function(p,i,x,e,l,s) 
            {p.slq||(s=p.slq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments)},s.queue=[],l=i.createElement(x),l.async=!0,l.src='https://v1.slise.xyz/scripts/pix3l.js',l.id='slise-pix3l',l.setAttribute('data-slise-adv-id',e),e=i.getElementsByTagName(x)[0],e.parentNode.insertBefore(l,e))}(window,document,'script','adv-162'); 
          `}
        </script>
      </Helmet>
      <Toaster />
      <div className="App">
        <GlobalStateContext>
          {/* { window.Notification && Notification && <FCM /> } */}
          <ThemeSettings />
          <Header
            setOpenedUserSettings={setOpenedUserSettings}
            openedUserSettings={openedUserSettings}
            onboardingDriver={onboardingDriver}
          />
          <div className="page-content">
            <div className="page-content-inner">
              {/* <AuthModal /> */}
              <Intro
                openedUserSettings={openedUserSettings}
                onboardingDriver={onboardingDriver}
                setOnboardingStage={setOnboardingStage}
                onboardingStage={onboardingStage}
              />
              <Investing />
              {/* <Staking /> */}
              {/* <Referrals /> */}
              <ReferralsBox />
              <Profit
                onboardingDriver={onboardingDriver}
                setOnboardingStage={setOnboardingStage}
                onboardingStage={onboardingStage}
              />
              <ReferralsList />
              <ReferralBonus />
              <InfoSections />
              <ContuctUs />
              <Footer />
            </div>
          </div>
          {isOnboardingModalOpen && (
            <OnboardingOfferModal
              setIsPopupOpen={setIsOnboardingModalOpen}
              stage={onboardingStage}
              onboardingDriver={onboardingDriver}
            />
          )}
        </GlobalStateContext>
      </div>
    </>
  );
}

export default App;
