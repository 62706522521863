import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import s from "./NotificationButton.module.sass";
import { toast } from "react-hot-toast";
import { sha256 } from 'js-sha256';
import { useAccount, useSignMessage } from "wagmi";
import { requestFCM } from "../../tools/requestFCM";
import {
  getServerTimestamp,
  isFcmTokenExistsRequest,
  createFcmTokenRequest,
} from "../../config/requests/requests";

import { BellIcon } from "../../assets/icons";

const NotificationButton = ({classNames}) => {
  const { address } = useAccount();
  const { t } = useTranslation();

  const [fcmToken, setFcmToken] = useState(null)
  const [timestamp, settimestamp] = useState(null)

  const { signMessage, data, isLoading, isError, error } = useSignMessage({
    onSuccess(data) {
      console.log('Successfully signed', data)
      if(fcmToken && timestamp) {
        createFcmTokenRequest(address.toLowerCase(), fcmToken, timestamp, data).then(() => {
          console.log('You are successfully subscribed on push-notifications!')
          toast.success(t("userSettings.pushNotifications.messages.successSubscribed"));
        }).catch(err => {
          console.error('createFcmTokenRequest: request error:', err, err.message)
        })
      } else {
        console.error('Action after signMessage: FCM token doesn\'t exist')
      }
    },
    onError(error) {
      setSaveRequestStatus(null)
      console.error("Error signing message:", error);
    },
  });

  useEffect(() => { // sign request
    if(fcmToken && address) {
      isFcmTokenExistsRequest(address.toLowerCase(), fcmToken).then(() => {
        console.log('FCM token already exist for current user!')
      }).catch(err => {
        if(err.response?.status === 404) {
          getServerTimestamp().then(({data: timestamp}) => {
            const nonce = `${address.toLowerCase()}${fcmToken}${timestamp}`
            settimestamp(timestamp)
            signMessage({ message: `Sign the message to allow notifications! Nonce:${sha256(nonce)}` });
          }).catch(err => {
            console.error('getServerTimestamp request error:', err, err.message)
          })
        } else {
          console.log('FCM isFcmTokenExistsRequest request error:', err, err.message)
        }
      })
    }
  }, [fcmToken, address])

  const pushNotificationHandler = async () => { // get permission and token 
    console.log('requested push')
    if(window.Notification && Notification) {
      const fcmTokenData = await requestFCM(t);

      console.log('fcmTokenData', fcmTokenData)
      if(fcmTokenData) {
        setFcmToken(fcmTokenData)
      } else {
        console.error('pushNotificationHandler: FCM token doesn\'t exist')
      }
    }
  }

  return (
    <button type="button" className={cn(s.button, classNames && classNames)} onClick={pushNotificationHandler}>
      <img src={BellIcon} />
      <span>{t("userSettings.pushNotifications.button.label")}</span>
    </button>
  );
};

export default NotificationButton;
